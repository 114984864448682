<template>
  <v-container class="container--fluid fill-height accent lighten-1">
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
        lg="4"
      >
        <v-card elevation="0" class="pa-3 rounded-3 card-box">
          <v-card-title class="d-flex align-center justify-center">
            <div class="layout column align-center">
              <v-img
                src="@/assets/logo.png"
                alt="Bina logo"
                width="120"
              />
            </div>
          </v-card-title>
          <v-card-text>
            <h1 class="mt-4 primary--text text--darken-1">
              {{ $t('login.resetPassword') }}
            </h1>
          </v-card-text>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-text-field
                v-model="model.password"
                name="password"
                :rules="passwordValid"
                :label="$t('login.password')"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                required
                autocomplete="current-password"
              />
              <v-text-field
                v-model="model.confirm"
                name="confirm"
                :rules="confirmValid"
                :label="$t('login.confirm')"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirmPassword ? 'text' : 'password'"
                @click:append="showConfirmPassword = !showConfirmPassword"
                required
              />
              <div class="mt-8">
                <localization />
                <v-btn
                  class="text-capitalize"
                  color="primary"
                  :disabled="!valid"
                  :loading="loading"
                  @click="resetPass"
                  width="80%"
                >
                  {{ $t('login.reset') }}
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Localization from '@/views/widget/AppLocalization.vue';
import { mapActions } from "vuex";

export default {
  name: 'ResetPassword',
  components: { Localization },
  data: () => ({
    loading: false,
    valid: false,
    model: {
      password: '',
      confirm: '',
    },
    disabledFields: false,
    showPassword: false,
    showConfirmPassword: false
  }),
  computed: {
    passwordValid() {
      return [
        v => !!v || this.$t('formRules.passwordRequired'),
        v => (v && (v.length >= 6 && v.length <= 20)) || this.$t('formRules.passwordValidMessage'),
      ];
    },
    confirmValid() {
      return [
        v => !!v || this.$t('formRules.passwordRequired'),
        v => (v && this.model.confirm === this.model.password) || this.$t('formRules.confirmValidMessage'),
      ];
    }
  },
  methods: {
    ...mapActions({
      resetPassword: "ResetPassword",
    }),
    async resetPass() {
      this.loading = true;
      const params = {
        password: this.model.password,
        token: this.$route.query.token
      };
      this.resetPassword(params)
      .then(() => {
        this.$router.push({ path: '/' });
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
    },
  },
};
</script>
<style scoped>
.card-box {
  box-shadow: 0 2px 10px 0 rgb(94 86 105 / 10%) !important;
  z-index: 1;
}
.auth-mask-bg {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.auth-tree,
.auth-tree-3 {
  position: absolute;
}
.auth-tree {
  bottom: 0;
  left: 0;
}
.auth-tree-3 {
  bottom: 0;
  right: 0;
}
</style>
